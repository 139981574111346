@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

*{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400;
}

body{
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.mobile{
  display: none !important;
}

.desktop{
  display: block !important;
}

.main-logo{
  height: 40px;
  width: auto;
}

.text-center{
  text-align: center !important;
}

.pointer{
  cursor: pointer;
}

.main-color{
  color: #172057;
}

.main-color-2{
  color: #007ec3;
}

.bg-main{
  background-color: #172057 !important;
}

.btn.bg-main{
  border-color: #172057;
}

.bg-main-2{
  background-color: #007ec3 !important;
}

.bg-gray{
  background-color: #e8e8e8 !important;
}

.middle{
  vertical-align: middle !important;
}

.title{
  font-size: 30px;
  font-weight: 600;
}

.title-agenda{
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.2rem;
}

.subtitle{
  font-size: .9rem;
  text-transform: capitalize;
  font-weight: 600;
}

.key-topic{
  background-color: #fff;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.key-topic img{
  width: 50%;
}

.key-topic-title{
  font-weight: bold;
  font-size: 20px;
}

.agenda{
  background-color: #fff;
  border-radius: 25px;
  border-width: 0px;
  width: 100% !important;
}

.agenda td{
  padding:10px 20px;
  vertical-align: top;
}

.agenda-main{
  position: relative;
  width: 230px;
  text-align: left !important;
}

.agenda .header td{
  padding-top: 0px;
  padding-bottom: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 1.1rem;
  text-transform: uppercase;
  vertical-align: middle;
  color: #000;
}

.agenda-main-wrapper{
  position: absolute;
  top: -20px;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #007ec3;
  border-radius: 20px 20px 0px 0px;
  color: #fff;
}

.agenda-footer-wrapper{
  position: absolute;
  bottom: -20px;
  top: 0;
  right: 0;
  left: 0;
  background-color: #007ec3;
  border-radius: 0px 0px 20px 20px;
  color: #fff;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.loading .modal-content{
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0;
}

.sisa-tiket{
  font-size: 11px;
}

.schedule-wrapper{
  background-size: cover;
  background-repeat: no-repeat;
}

.schedule-wrapper img.title{
  width: 400px;
}

.agenda .time{
  font-size: 18px;
  width: 140px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.book-now{
  border-radius: 10px;
  border-width: 0px;
  font-size: .7rem;
  text-transform: capitalize !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 600;
}

a.book-now:hover{
  color: #fff;
}

.book-now.enable{
  background: rgb(0,126,195);
  background: linear-gradient(177deg, rgba(0,126,195,1) 0%, rgba(9,85,147,1) 89%, rgba(9,85,147,1) 100%);
}

.btn-form{
  font-size: 1rem !important;
  padding: .375rem .75rem;
}

.book-now.full{
  background: rgb(195,0,23);
  background: linear-gradient(177deg, rgba(195,0,23,1) 0%, rgba(147,9,9,1) 89%, rgba(147,9,9,1) 100%);
}

.book-now.white, .book-now.white:hover{
  background-color: #fff;
  color: #000;
}

.book-now.booked{
  background: rgb(53,195,0);
  background: linear-gradient(177deg, rgba(53,195,0,1) 0%, rgba(88,147,9,1) 89%, rgba(72,147,9,1) 100%);
}

.panel-desc{
  margin-top: 10px;
  font-size: .8rem;
  margin-bottom: 5px;
  line-height: .9rem;
}

.panel-speaker{
  font-size: .7rem;
  line-height: .8rem;
}

.border-right-line{
  border-right-width: 1px !important;
  border-right-color: #007ec3 !important;
  border-right-style: solid;
}

.agenda-mobile{
  display: none;
  background-color: #fff;
  border-width: 0px;
  width: 100% !important;
  border-radius: 10px;
  overflow: hidden;
}

.breakout-card{
  border-radius: 10px;
  border: 1px solid #ccc;
}

.speaker-img{
  width: 100%;
}

.keynote-wrap{
  width: 100%;
}

.keynote-header{
  background-color: red;
  padding: 10px 20px 40px 20px;
  display: block;
  background: rgb(0,178,173);
  background: linear-gradient(177deg, rgba(0,178,173,1) 0%, rgba(0,185,134,1) 52%, rgba(0,192,93,1) 100%);
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  color: #000;
  font-size: 1.1rem;
  font-weight: 600;
}

.keynote-body{
  background-color: #fff;
  border-radius: 30px;
  margin-top: -30px;
  color: #000;
  width: 100%;
  padding: 20px;
}

.image-profile{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.border-10{
  border-radius: 10px !important;
}

.border-20{
  border-radius: 20px !important;
}

.MuiOutlinedInput-root{
  border-radius: 10px !important;
}

.modal-body{
  background-color: aliceblue;
  padding: 1.5rem !important;
}

.MuiOutlinedInput-input{
  background-color: #fff !important;
}

.modal-content{
  border-radius: 20px !important;
  overflow: hidden;
}

.modal-content .close{
  background-color: #ccc;
  margin-right: -2px !important;
  margin-top: -2px !important;
  padding: 0px !important;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.modal-content .close span{
  font-size: 2rem;
}

.modal-title{
  font-weight: 600 !important;
}

.footer{
  padding: 20px;
  background-color: #292929;
  color: #fff;
  text-align: center;
  font-size: .9rem;
}

.doorprize{
  padding: 40px 30px 20px 30px;
}

.doorprize .title{
  font-size: 1.4rem;
  font-weight: 600;
  font-style: italic !important;
}

.doorprize img{
  width: 200px;
  margin-top: -80px;
  object-fit: contain;
}

.doorprize .subtitle{
  font-size: 1.1rem;
  font-weight: 600;
  font-style: italic !important;
}

.sponsor{
  font-size: 1.1rem;
  letter-spacing: 6px;
}

.sponsor-item:hover{
  transform: scale(1.3);
  transition: transform .2s ease;
}

.line-ntaf{
  height: 1px;
  background-color: #007ec3;
}

@media (min-width: 769px) and (max-width: 991px) {
  .mobile{
    display: none;
  }
  
  .desktop{
    display: block;
  }

  .container{
    max-width: 100% !important;
    width: 100%;
  }
  .agenda-main{
    width: 20vw;
  }
}

@media (max-width: 767px) {
  .mobile{
    display: block !important;
  }
  
  .desktop{
    display: none !important;
  }

  .main-logo{
    height: 25px;
  }

  .key-topic-title{
    font-weight: bold;
    font-size: 12px;
  }

  .agenda{
    display: none;
  }

  .agenda-mobile{
    display: block;
  }

  .key-topic{
    background-color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .doorprize{
    padding: 20px 20px 20px 10px;
  }

  .doorprize .title{
    font-size: 1rem;
    font-weight: 600;
    font-style: italic !important;
  }
  
  .doorprize img{
    width: 100px;
    object-fit: contain;
    margin-top: -40px;
  }
  
  .doorprize .subtitle{
    font-size:.8rem;
    font-weight: 600;
    font-style: italic !important;
  }
  
}

@media (max-width: 576px) {
  .mobile{
    display: block !important;
  }
  
  .desktop{
    display: none !important;
  }

  .schedule-wrapper img.title{
    width: 250px;
  }
}