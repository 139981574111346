@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap);
*{
    font-family: 'Open Sans' !important;
}
/* html {
    scroll-behavior: smooth;
} */
body {
    /* padding: 0 3%; */
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans', sans-serif;
  }
  
a:hover{
    text-decoration: none !important;
    }

a {
    text-decoration: none !important;
}


.caption{
    /* border: 1px solid green; */
    /* margin: 0; */
    position: absolute;
    /* right: 15%; */
    bottom: 0;
    left: 0;
    z-index: 10;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    margin: 0;
    border-radius: 0px 0px 10px 10px;
    /* text-align: center; */
}

.slick-prev {
    left: 1% !important;
    z-index: 1;
    color: gray !important;
  background-color: white !important;
  text-decoration: none;
  }
  .slick-next {
    right: 3% !important;
    z-index: 1;
    color: gray !important;
  background-color: white !important;
  text-decoration: none;
  }

  .slick-prev:before,
.slick-next:before {
  color: gray !important;
  background-color: white !important;
  text-decoration: none;
}


button:focus {
    outline: none !important;
}

.MuiToolbar-gutters {
    padding-left: 0!important;
    padding-right: 0!important;
}

.examBox{
   background-color: black;
   color: red;
   position: absolute;
   display: none;
}  

.examBox:hover{
    color: blue;
    box-shadow: 0 0 10px #ccc;
    display: block;
}

.showName {
    display: block !important;
    cursor: pointer;
}

.showName:hover {
    display: none !important;
    cursor: pointer;
}

.boxHover {    
    display: block;    
}

.boxHover:hover {    
    display: none !important;    
}

.MuiAppBar-colorSecondary{
    background-color: 'green !important'
}

body {
    font-family: 'Open Sans', sans-serif;
}

#fontStyle {
    font-family: 'Open Sans', sans-serif;
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}


/* .react-datepicker, .form-control, .dateInput{
    position: relative !important;
    z-index: 99 !important;
} */

.react-datepicker-popper { 
    z-index: 100 !important 
}

#menu-list-grow {
    color: '#343c5a';
    margin-top: 16px;
    padding-top: 20px;
    padding-bottom: 50px;
    padding-bottom: 10px;
    width: 240px;
    height: 'fit-content';
    background-color: '#fbfbfb';
}


/* SINGLE EVENT */


.imgstyle {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* border-radius: 5px; */
    /* max-width: 1240px; */
    height: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: gray;
    box-sizing: border-box;
    /* filter: blur(3px); */
  /* -webkit-filter: blur(3px); */
  background: linear-gradient(rgba(138, 133, 133, 0.367), rgba(163, 161, 161, 0.306))
}

.paperStyles {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    /* height: auto;     */
    margin-top: 10px;
}

#eventContent {
    white-space: pre-line;
}

#eventContent img {
    max-width: 100%;
    text-align: center;
}

#eventContent p {
    width: 100%;
    text-align: justify;
}

/* .ticketTab {
    padding-top: 0 !important;
    padding: 5px;
} */
.buttontab{
    /* color: #39364F !important; */
    font-weight: bold !important;
    border-radius: 30px !important;
    font-size: 13px !important;
    margin: 3.5px !important;
}

.mobile-button::-webkit-scrollbar {
    display: none;
}

.agendaTab {
    padding: 20px;
}

.deskripsiTab {
    padding: 25px;
}

.paperStyleRoot {
    padding-left: 50px;
    padding-top: 10px;
    padding-right: 5px;
    padding-bottom: 10px;
}

.imageSeat {
    cursor: pointer;
    height: 60%;
    width: 60%;
}

.styleImgGalery {
    padding: 5px;
    border-radius: 20px;
    width: 100% !important;
}




/* Category Button */
.buttonCategory{
    text-transform: capitalize !important;
    border-radius: 30px !important;
    font-size: 12px !important;
    margin: 2px !important;
}

.buttonCategory:hover, .buttonTabs:hover{
    color: white !important;
    border-radius: 30px !important;
    background-color: #9E69D2 !important;
}

.buttonCategory21{
    text-transform: capitalize !important;
    border-radius: 30px !important;
    font-size: 12px !important;
    margin: 2px !important;
}

.buttonCategory21:hover{
    color: white !important;
    border-radius: 30px !important;
    background-color: #BF382D !important;

}

.true{
    background-color: #9E69D2 !important;

}

.buttonmore{
    width: 100% !important;
    background-color: #9E69D2 !important;
    text-transform: capitalize !important;
    border-radius: 30px !important;
    color: white !important;
}

.buttonTabs{
    /* color: #39364F !important; */
    font-weight: bold !important;
    border-radius: 30px !important;
    font-size: 13px !important;
    margin: 5px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    width: 100% !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.boxmore{
    display: flex;
}



@media (min-width: 1600px) and (max-width: 2900px) {
    .mobile{
        display: none !important;
    }
    .deks{
        display: block !important;
    }
    .singleimg{
        height: 450px !important;
    }
    .singleimg-inactive{
        top:84px !important;
    }
    .boxmore{
        justify-content: flex-end;
    }
    .panduan{
        padding:12% 18%;
    }
    .title-panduan{
        text-align: center;
        padding-bottom: 5%;
        font-size: 27px;
        color: #9E69D2;
        font-weight: bold;
    }
    .question{
        font-size: 20px;
        font-weight: bold;
    }
    .imgcreate{
        height: 530px;
    }

    .paperStyles {
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        /* height: 250px;     */

        height: auto;
        padding-bottom: 35px;

        margin-top: 10px;
        padding-bottom: 35px;
    }
    .footer-container{
        margin-top: 2rem;
        padding-right: 1rem;
        padding-left: 1rem;
    }

    
}

@media (min-width: 1025px) and (max-width: 1224px) {
    .singleimg-inactive{
        top:102px !important;
    }
}
@media (min-width: 1225px) and (max-width: 1600px) {
    .singleimg-inactive{
        top:85px !important;
    }
}

/* Responsive CSS LG */
@media (min-width: 1025px) and (max-width: 1600px) {
    .mobile{
        display: none !important;
    }
    .deks{
        display: block !important;
    }
    .imgstyle {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        /* border-radius: 5px; */
        height: 458px;
        /* height: 581px; */
    

    }
    .singleimg{
        /* height: 581px !important; */
        height: 458px !important;
    }
    
    .paperStyles {
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        /* height: 250px;     */

        height: auto;
        padding-bottom: 35px;

        margin-top: 10px;
        padding-bottom: 35px;
    }

/* 
    .ticketTab {
        padding-top: 0 !important;
        padding: 10px;
    } */

    .agendaTab {
        padding: 20px;
        /* min-height: 350px; */

    }

    .galleryTab {
        padding: 10px;
        /* min-height: 350px; */

    }

    .penyelenggaraTab{
        padding: 30px;
    }
    .deskripsiTab {
        padding: 20px;
        /* min-height: 350px; */
    }

    .paperStyleRoot {
        padding-left: 50px;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    .imageSeat {
        cursor: pointer;
        height: 70%;
        width: 70%;
    }

    .styleImgGalery {
        padding: 5px;
        border-radius: 20px;
    }   
    
    .boxmore{
        justify-content: flex-end;
    }

    /* create event */
    .container-create{
        margin-top: 5rem;
        margin-bottom: 5rem;
        /* background-color: #f9f9f9; */
    }

    .imgcreate{
        height: 530px;
    }
    .createEvents{
        padding-left: 4%;
        padding-top: 7%;
        
    }
    .caption-create{
        padding-top: 4%;
        line-height: 28px;
    }
   
    /* FAQ */
    .panduan{
        padding:12% 8%;
    }
    .title-panduan{
        text-align: center;
        padding-bottom: 5%;
        font-size: 25px;
        color: #9E69D2;
        font-weight: bold;
    }
    .question{
        font-size: 16px;
        font-weight: bold;
        color: #393755;
    }

    .answer{
        font-size: 15px;
        color: #393755;

    }

    /* SK */
    .syarat-ketentuan{
        padding:12% 18%;
    }
    
    .title-syarat{
        text-align: center;
        padding-bottom: 8%;
        font-size: 30px;
        color: #9E69D2;
        font-weight: bold;
    }
    
    .konten{
        font-size: 15px;
        line-height: 27px;
        padding-bottom: 2rem;
      color:  rgb(91, 90, 90);
    
    }
    
    .konten-penting{
        line-height: 25px;
        font-size: 15px;
        font-weight: bold;
        color: rgb(62, 61, 61);
    
    
    }
    
    .konten-bagian{
        font-size: 20px;
        color: rgb(81, 79, 79);
        font-weight: bold;
        padding-bottom: 20px;
        padding-top: 4.5rem;
    
    }
    
    .title-bagian{
        color: rgb(91, 90, 90);
        line-height: 27px;
        font-size: 15px;
    }
    
    .list-bagian{
      color: rgb(91, 90, 90) !important;
        line-height: 27px;
        font-size: 15px;
    }
    
    .list-bagian ol li{
      color: rgb(91, 90, 90) !important;
    
    }
    .footer-container{
        margin-top: 2rem;
        padding-right: 1rem;
        padding-left: 1rem;
    }

}

    /* searched-event */
    .card-event{
        width: 220px;
        margin: 20px 10px;
        position: relative;
    }

    .image-event{
        -webkit-filter: brightness(0.8);
                filter: brightness(0.8);
        width: 100%;
        height: 105px;
    }

    .nama-event{
        font-size: 13px;
    }
    .caption-event{
        font-size: 12px;
    }

    .qrcode{
        width: 115px !important;
        height: 115px !important;
    }

    @media(min-width: 769px) and (max-width: 959px){
        .mobile{
            display: none !important;
        }
        .deks{
            display: block !important;
        }
    }



/* Responsive CSS MD */
@media (min-width: 769px) and (max-width: 1024px) {
    .mobile{
        display: none !important;
    }
    .deks{
        display: block !important;
    }
   
    
    .searchbar{
        display: none;
    }
    .imgstyle {        
        height: 350px;
    }
    .singleimg{
        height: 350px !important;
    }
    .singleimg-inactive{
        top:102px !important;
    }
    .paperStyles {        
        /* height: 220px;     */
        padding-bottom: 35px;

        margin-top: 10px;
    }

    /* .ticketTab {
        padding-top: 0 !important;
        padding: 10px;
    } */

    .agendaTab {
        padding: 20px;
    }

    .deskripsiTab {
        padding: 20px;
    }

    .paperStyleRoot {
        padding-left: 40px;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    .imageSeat {
        cursor: pointer;
        height: 80%;
        width: 80%;
    }

    .styleImgGalery {
        padding: 5px;
        border-radius: 15px;
    }
    .boxmore{
        justify-content: flex-end;
    }
    .imgcreate{
        height: 530px;
    }

    /* create event */
    .container-create{
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
    .createEvents{
        padding-left: 4%;
        padding-top: 7%;
        font-size: 15px;
        
    }
    .caption-create{
        padding-top: 4%;
        line-height: 26px;
    }
    

     /* FAQ */
     .panduan{
        padding:18% 8%;
    }
    .title-panduan{
        text-align: center;
        padding-bottom: 5%;
        font-size: 25px;
        color: #9E69D2;
        font-weight: bold;
    }
    .question{
        font-size: 17px;
        font-weight: bold;
        color: #393755;
    }

    .answer{
        font-size: 15px;
        color: #393755;

    }


    /* SK */
    .syarat-ketentuan{
        padding:18% 13%;
    }
    
    .title-syarat{
        text-align: center;
        padding-bottom: 8%;
        font-size: 30px;
        color: #9E69D2;
        font-weight: bold;
    }
    
    .konten{
        font-size: 15px;
        line-height: 27px;
        padding-bottom: 2rem;
      color:  rgb(91, 90, 90);
    
    }
    
    .konten-penting{
        line-height: 25px;
        font-size: 15px;
        font-weight: bold;
        color: rgb(62, 61, 61);
    
    
    }
    
    .konten-bagian{
        font-size: 20px;
        color: rgb(81, 79, 79);
        font-weight: bold;
        padding-bottom: 20px;
        padding-top: 4.5rem;
    
    }
    
    .title-bagian{
        color: rgb(91, 90, 90);
        line-height: 27px;
        font-size: 15px;
    }
    
    .list-bagian{
      color: rgb(91, 90, 90) !important;
        line-height: 27px;
        font-size: 15px;
    }
    
    .list-bagian ol li{
      color: rgb(91, 90, 90) !important;
    
    }

    /* searched-event */
.card-event{
    width: 90%;
    margin: 15px 10px;
    position: relative;
}

.image-event{
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    width: 100%;
    height: 100px;
}

.nama-event{
    font-size: 13px;
}
.caption-event{
    font-size: 12px;
}

.footer-container{
    margin-top: 2rem;
    padding-right: 1rem;
    padding-left: 2rem;
}


}

@media (min-width: 483px) and (max-width: 570px) {

    .singleimg-inactive{
        top:120px !important;
    }
}

@media (min-width: 570px) and (max-width: 677px) {

    .singleimg-inactive{
        top:120px !important;
    }
}

@media (min-width: 678px) and (max-width: 768px) {

    .singleimg-inactive{
        top:103px !important;
    }
}

/* Responsive CSS SM */
@media (min-width: 481px) and (max-width: 768px) {
    .deks{
        display: none !important;
    }
    .mobile{
        display: block !important;
    }
    .searchbar{
        display: none;
    }
    .imgstyle {        
        height: 251px !important;
    }
    .singleimg{
        height: 250px !important;
    
    }

    /* .singleimg-inactive{
        top:164px !important;
    } */
    
    .paperStyles {        
        height: -webkit-fit-content;        
        height: -moz-fit-content;        
        height: fit-content;  
        padding-bottom: 15px;
        margin-top: 10px;
    }
/* 
    .ticketTab {
        padding-top: 0 !important;
        padding: 5px;
    } */

    .agendaTab {
        padding: 20px;
    }

    .deskripsiTab {
        padding: 25px;
    }

    .paperStyleRoot {
        padding-left: 30px;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    .imageSeat {
        cursor: pointer;
        height: 80%;
        width: 80%;
    }

    .styleImgGalery {
        padding: 4px;
        border-radius: 10px;
    }
    
    .boxmore{
        justify-content: flex-end;
    }

    /* create event */
    .container-create{
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .imgcreate{
        height: 530px;
    }
    .createEvents{
        padding-left: 4%;
        padding-top: 6%;
        
    }
    .caption-create{
        padding-top: 4%;
        line-height: 22px;
        font-size: 15px;
    }

     /* FAQ */
     .panduan{
        padding:18% 8%;
    }
    .title-panduan{
        text-align: center;
        padding-bottom: 5%;
        font-size: 22px;
        color: #9E69D2;
        font-weight: bold;
    }
    .question{
        font-size: 14px;
        font-weight: bold;
        color: #393755;
    }

    .answer{
        font-size: 13px;
        color: #393755;

    }


    /* SK */
    .syarat-ketentuan{
        padding: 8rem 3rem;
    }
    
    .title-syarat{
        text-align: center;
        padding-bottom: 3rem;
        font-size: 25px;
        color: #9E69D2;
        font-weight: bold;
    }
    
    .konten{
        font-size: 15px;
        line-height: 25px;
        padding-bottom: 2rem;
      color:  rgb(91, 90, 90);
    
    }
    
    .konten-penting{
        line-height: 22px;
        font-size: 14px;
        font-weight: bold;
        color: rgb(62, 61, 61);
    
    
    }
    
    .konten-bagian{
        font-size: 18px;
        color: rgb(81, 79, 79);
        font-weight: bold;
        padding-bottom: 20px;
        padding-top: 4rem;
    
    }
    
    .title-bagian{
        color: rgb(91, 90, 90);
        line-height: 25px;
        font-size: 14px;
    }
    
    .list-bagian{
      color: rgb(91, 90, 90) !important;
        line-height: 25px;
        font-size: 14px;
    }
    
    .list-bagian ol li{
      color: rgb(91, 90, 90) !important;
    
    }

       /* searched-event */
    .card-event{
        width: 60%;
        margin: 10px 8px;
        position: relative;
    }

    .image-event{
        -webkit-filter: brightness(0.8);
                filter: brightness(0.8);
        width: 100%;
        height: 130px;
    }

    .nama-event{
        font-size: 12px;
    }
    .caption-event{
        font-size: 11px;
    }

    .title-search{
        font-size: 20px;
    }
    .footer-container{
        margin-top: 1.2rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

}
@media (min-width: 400px) and (max-width: 480px) {  
    .singleimg-inactive{
        top:136px !important;
    }
}
@media (min-width: 320px) and (max-width: 400px) {  
    .singleimg-inactive{
        top:153px !important;
    }
}

@media (min-width: 200px) and (max-width: 319px) {  
    .singleimg-inactive{
        top:188px !important;
    }
}
/* 360 410 */

@media (min-width: 320px) and (max-width: 327px) {

    .singleimg-inactive{
        top:170px !important;
    }
}
@media (min-width: 328px) and (max-width: 392px) {
    .singleimg-inactive{
        top:153px !important;
    }
}

@media (min-width: 392px) and (max-width: 480px) {
    .singleimg-inactive{
        top:137px !important;
    }
}
/* Responsive CSS XS */
@media (min-width: 320px) and (max-width: 480px) {  
    .mobile{
        display: block !important;
    }
    .deks{
        display: none !important;
    }
    .searchbar{
        display: none;
    }

    .imgstyle {        
        height: 181px;
    }
    .singleimg{
        height: 180px !important;
        
    }
    
    
    .paperStyles {        
        height: -webkit-fit-content;        
        height: -moz-fit-content;        
        height: fit-content;  
        padding-bottom: 15px;  
        margin-top: 10px;
    }
    
    /* .ticketTab {
        padding-top: 0 !important;
        padding: 0px;
    } */

    .galleryTab {
        padding: 10px;
    }

    .agendaTab {
        padding: 20px;
    }

    .deskripsiTab {
        padding: 25px;
    }

    .paperStyleRoot {
       padding: 5px;
    }

    .imageSeat {
        cursor: pointer;
        height: 90%;
        width: 90%;
    }

    .styleImgGalery {
        padding: 2px;
        border-radius: 10px;
    }
    .boxmore{
        justify-content: center;
    }

    /* create event */
    .container-create{
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .imgcreate{
        height: 440px;
    }
    .createEvents{
        padding-left: 4%;
        padding-top: 3%;
        
    }
    .caption-create{
        padding-top: 4%;
        line-height: 27px;
        font-size: 14px;

    }

     /* FAQ */
     .panduan{
        padding:25% 8%;
    }
    .title-panduan{
        text-align: center;
        padding-bottom: 5%;
        font-size: 20px;
        color: #9E69D2;
        font-weight: bold;
    }
    .question{
        font-size: 13px;
        font-weight: bold;
        color: #393755;
    }

    .answer{
        font-size: 12px;
        color: #393755;

    }


    /* SK */
    .syarat-ketentuan{
        padding: 8rem 2rem;
    }
    
    .title-syarat{
        text-align: center;
        padding-bottom: 3rem;
        font-size: 20px;
        color: #9E69D2;
        font-weight: bold;
    }
    
    .konten{
        font-size: 13px;
        line-height: 20px;
        padding-bottom: 2rem;
      color:  rgb(91, 90, 90);
    
    }
    
    .konten-penting{
        line-height: 20px;
        font-size: 13px;
        font-weight: bold;
        color: rgb(62, 61, 61);
    
    
    }
    
    .konten-bagian{
        font-size: 15px;
        color: rgb(81, 79, 79);
        font-weight: bold;
        padding-bottom: 16px;
        padding-top: 3rem;
    
    }
    
    .title-bagian{
        color: rgb(91, 90, 90);
        line-height: 20px;
        font-size: 13px;
    }
    
    .list-bagian{
      color: rgb(91, 90, 90) !important;
        line-height: 20px;
        font-size: 13px;
    }
    
    .list-bagian ol li{
      color: rgb(91, 90, 90) !important;
    
    }

    /* searched-event */
    .card-event{
        width: 90%;
        margin: 10px 8px;
        position: relative;
    }

    .image-event{
        -webkit-filter: brightness(0.8);
                filter: brightness(0.8);
        width: 100%;
        height: 130px;
    }

    .nama-event{
        font-size: 12px;
    }
    .caption-event{
        font-size: 11px;
    }

    .title-search{
        font-size: 17px;
    }

    .footer-container{
        margin-top: 1rem;
        padding-right: 0.8rem;
        padding-left: 0.8rem;
    }
  

}

/* Responsive CSS XS */
@media (min-width: 200px) and (max-width: 319px) {  
    .mobile{
        display: block !important;
    }
    .deks{
        display: none !important;
    }
    .searchbar{
        display: none;
    }
    .imgstyle {        
        height: 150px;
    }

    .singleimg{
        height: 149.8px !important;
    }
   
    
    .paperStyles {        
        height: -webkit-fit-content;        
        height: -moz-fit-content;        
        height: fit-content;  
        padding-bottom: 10px;  
        margin-top: 10px;
    }

    /* .ticketTab {
        padding: 0px;
    } */

    .paperStyleRoot {
        padding: 3px;
     }

     .agendaTab {
        padding: 10px;
    }

    .deskripsiTab {
        padding: 10px;
    }

     .imageSeat {
        cursor: pointer;
        height: 100%;
        width: 100%;
    }

    .styleImgGalery {
        padding: 2px;
        border-radius: 5px;
    }

    .boxmore{
        justify-content: center;
    }
    
    /* create event */
    .container-create{
        margin-top: 3.5rem;
        margin-bottom: 3.5rem;
    }
    .imgcreate{
        height: 440px;
    }
    
    .caption-create{
        padding-top: 4%;
        line-height: 25px;
        font-size: 14px;

    }

     /* FAQ */
     .panduan{
        padding:45% 8%;
    }
    .title-panduan{
        text-align: center;
        padding-bottom: 5%;
        font-size: 19px;
        color: #9E69D2;
        font-weight: bold;
    }
    .question{
        font-size: 13px;
        font-weight: bold;
        color: #393755;
    }

    .answer{
        font-size: 12px;
        color: #393755;

    }


    /* SK */
    .syarat-ketentuan{
        padding: 8rem 1.5rem;
    }
    
    .title-syarat{
        text-align: center;
        padding-bottom: 2.5rem;
        font-size: 17px;
        color: #9E69D2;
        font-weight: bold;
    }
    
    .konten{
        font-size: 12px;
        line-height: 20px;
        padding-bottom: 2rem;
        color:  rgb(91, 90, 90);
    
    }
    
    .konten-penting{
        line-height: 18px;
        font-size: 12px;
        font-weight: bold;
        color: rgb(62, 61, 61);
    
    
    }
    
    .konten-bagian{
        font-size: 14px;
        color: rgb(81, 79, 79);
        font-weight: bold;
        padding-bottom: 15px;
        padding-top: 2rem;
    
    }
    
    .title-bagian{
        color: rgb(91, 90, 90);
        line-height: 20px;
        font-size: 12px;
    }
    
    .list-bagian{
      color: rgb(91, 90, 90) !important;
        line-height: 20px;
        font-size: 12px;
    }
    
    .list-bagian ol li{
      color: rgb(91, 90, 90) !important;
    
    }

     /* searched-event */
     .card-event{
        width: 88%;
        margin: 10px 8px;
        position: relative;
    }

    .image-event{
        -webkit-filter: brightness(0.8);
                filter: brightness(0.8);
        width: 100%;
        height: 120px;
    }

    .nama-event{
        font-size: 12px;
    }
    .caption-event{
        font-size: 11px;
    }

    .title-search{
        font-size: 15px;
    }

    .footer-container{
        margin-top: 1rem;
        padding-right: 1rem;
        padding-left: 1rem;
    }


}

@media (min-width: 100px) and (max-width: 200px) {  
    .searchbar{
        display: none;
    }
    .mobile{
        display: block  !important;
    }
    .deks{
        display: none !important;
    }
}





.MuiToolbar-root{
    color: #9E69D2 !important;
}



.imgcreate {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* height: 560px; */
    width: 100%;
}

.btnRemoveFav{
    color: white
}

.btnRemoveFav:hover{
    color: red;
}

.dialogtitle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* background-image: sr; */
}


.tiket {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    height: 200px;
    width: 400px;
    font-size: 15px;
    /* border: 1px solid gray; */
    /* width: 100%; */
}

.selengkapnya:hover{
    text-decoration: underline;
}



/* 
.syarat-ketentuan{
    padding:12% 18%;
}
.title-syarat{
    text-align: center;
    padding-bottom: 8%;
    font-size: 30px;
    color: #9E69D2;
    font-weight: bold;
}
.konten{
    font-size: 15px;
    line-height: 27px;
    padding-bottom: 2rem;
  color:  rgb(91, 90, 90);
}
.konten-penting{
    line-height: 25px;
    font-size: 15px;
    font-weight: bold;
    color: rgb(62, 61, 61);
}
.konten-bagian{
    font-size: 20px;
    color: rgb(81, 79, 79);
    font-weight: bold;
    padding-bottom: 20px;
    padding-top: 4.5rem;
}
.title-bagian{
    color: rgb(91, 90, 90);
    line-height: 27px;
    font-size: 15px;
}
.list-bagian{
  color: rgb(91, 90, 90) !important;
    line-height: 27px;
    font-size: 15px;
}
.list-bagian ol li{
  color: rgb(91, 90, 90) !important;
} */

.search-result{
    padding-top: 6rem;
}

.button-filter{
    /* color: #9E69D2 !important; */
    border: 1px solid #9E69D2 !important;
}

.button-filter:hover{
    background-color: #9E69D2 !important;
    color: white;
}


/* footer */
.footer-tentang{
    font-size: 12px ;
    line-height: 20px;
    padding-left: 4px;
}

.footer-list, .footer-list-pym{
    font-size: 13px;
    text-decoration: none;
    color: rgb(36, 36, 36);
}
.footer-list:hover{
    font-weight: bold;
    text-decoration: none;
    color: rgb(36, 36, 36);

}




.imgevent{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-filter: blur(50px);
            filter: blur(50px);
  /* -webkit-filter: blur(8px); */
  background: linear-gradient(rgba(138, 133, 133, 0.367), rgba(163, 161, 161, 0.306))
    /* background-color: rgba(21, 20, 20, 0.763); */
}

.imge{
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);

}

.imge img {
    transition: 0.6s ease;
  }

.imge:hover img {
    transform: scale(1.2);
  }

.imgbanner img {
    transition: 0.6s ease;
  }

  .imgbanner:hover img {
    transform: scale(1.2);
  }



.imgsearch{
    position: absolute;
    /* top: 34%; */
    top: 31%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
}

.singleimg{
    position: absolute;
    top:0;
    left: 50%;
    transform: translate(-50%, 0%);
    /* filter: brightness(0.9); */
}
.singleback{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-filter: blur(34px);
            filter: blur(34px);
  /* -webkit-filter: blur(8px); */
  background: linear-gradient(rgba(138, 133, 133, 0.367), rgba(163, 161, 161, 0.306))
}

.fullscreen{
    position: absolute;
    top:2px;
    right: 2.2%;
    background-color: rgba(143, 143, 143, 0.694);
    z-index: 99;
    cursor: pointer;
    }

.fullscreen img{
    width:28px;
    transition: 0.6s ease;
}

.fullImage{
    margin: 70px 0 0 0;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;  
    background-color: rgba(42, 42, 42, 0.694);
    display: inherit;
    cursor: pointer;
}

.fullImage:hover img{
    transition: transform 0.25s ease;
}

.sharee{
    position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 5px;
  background-color: #cae8ca;
  border: 2px solid #4CAF50;
}

.popup_private{
    background-color: rgba(42, 42, 42, 0.749);

}

.btnTag:hover{
    color:#393755;
    font-weight: bold;
    border-color: #393755;

}

.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 12px;
}

@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 4px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 18px;
        right: 18px;
        font-size: 22px;
    }
}


.btn-subscribe{
    background-color: #7C71D5 !important;
    color: white !important;
}

.btn-subscribe:hover{
    background-color: #132461 !important;
    color: white !important;

}

input#controlled-demo{
    font-weight: bold !important;
    font-size: 17px !important;
    color: #707070 !important;
}

.transaksi{
    cursor: pointer;
}
.transaksi:hover{
    border:1px solid #b285dfec;
    border-radius: 3px;
}

.detail_name:hover{
    color:#39364F !important
}

.scrollTop {
    position: fixed; 
    z-index: 1000;
    /* width: 80%; */
    width:50px;
    right: 18px !important;
    height: 20px;
    cursor: pointer;
    -webkit-animation: fadeIn 0.3s;
            animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
    bottom: 20px;
  }
  .scrollTop:hover{
    opacity: 4;
    color: #9E69D2 !important;
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  #containers {
    height: 60px;
    overflow: hidden;
}
#modules {
    height:90px; /* 40px - more place for scrollbar, is hidden under parent box */
    padding:5px;
    white-space:nowrap;
    overflow-x: scroll;
    overflow-y: hide;
	-webkit-overflow-scrolling:touch;
}
.module {
    display:inline-block;
    width:87px;
    height:50px;
    line-height:50px;
    text-align:center;
    background:#ddd;
}
.module + .module {
    margin-left:5px
}
*{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400;
}

body{
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.mobile{
  display: none !important;
}

.desktop{
  display: block !important;
}

.main-logo{
  height: 40px;
  width: auto;
}

.text-center{
  text-align: center !important;
}

.pointer{
  cursor: pointer;
}

.main-color{
  color: #172057;
}

.main-color-2{
  color: #007ec3;
}

.bg-main{
  background-color: #172057 !important;
}

.btn.bg-main{
  border-color: #172057;
}

.bg-main-2{
  background-color: #007ec3 !important;
}

.bg-gray{
  background-color: #e8e8e8 !important;
}

.middle{
  vertical-align: middle !important;
}

.title{
  font-size: 30px;
  font-weight: 600;
}

.title-agenda{
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.2rem;
}

.subtitle{
  font-size: .9rem;
  text-transform: capitalize;
  font-weight: 600;
}

.key-topic{
  background-color: #fff;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.key-topic img{
  width: 50%;
}

.key-topic-title{
  font-weight: bold;
  font-size: 20px;
}

.agenda{
  background-color: #fff;
  border-radius: 25px;
  border-width: 0px;
  width: 100% !important;
}

.agenda td{
  padding:10px 20px;
  vertical-align: top;
}

.agenda-main{
  position: relative;
  width: 230px;
  text-align: left !important;
}

.agenda .header td{
  padding-top: 0px;
  padding-bottom: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 1.1rem;
  text-transform: uppercase;
  vertical-align: middle;
  color: #000;
}

.agenda-main-wrapper{
  position: absolute;
  top: -20px;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #007ec3;
  border-radius: 20px 20px 0px 0px;
  color: #fff;
}

.agenda-footer-wrapper{
  position: absolute;
  bottom: -20px;
  top: 0;
  right: 0;
  left: 0;
  background-color: #007ec3;
  border-radius: 0px 0px 20px 20px;
  color: #fff;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.loading .modal-content{
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0;
}

.sisa-tiket{
  font-size: 11px;
}

.schedule-wrapper{
  background-size: cover;
  background-repeat: no-repeat;
}

.schedule-wrapper img.title{
  width: 400px;
}

.agenda .time{
  font-size: 18px;
  width: 140px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.book-now{
  border-radius: 10px;
  border-width: 0px;
  font-size: .7rem;
  text-transform: capitalize !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 600;
}

a.book-now:hover{
  color: #fff;
}

.book-now.enable{
  background: rgb(0,126,195);
  background: linear-gradient(177deg, rgba(0,126,195,1) 0%, rgba(9,85,147,1) 89%, rgba(9,85,147,1) 100%);
}

.btn-form{
  font-size: 1rem !important;
  padding: .375rem .75rem;
}

.book-now.full{
  background: rgb(195,0,23);
  background: linear-gradient(177deg, rgba(195,0,23,1) 0%, rgba(147,9,9,1) 89%, rgba(147,9,9,1) 100%);
}

.book-now.white, .book-now.white:hover{
  background-color: #fff;
  color: #000;
}

.book-now.booked{
  background: rgb(53,195,0);
  background: linear-gradient(177deg, rgba(53,195,0,1) 0%, rgba(88,147,9,1) 89%, rgba(72,147,9,1) 100%);
}

.panel-desc{
  margin-top: 10px;
  font-size: .8rem;
  margin-bottom: 5px;
  line-height: .9rem;
}

.panel-speaker{
  font-size: .7rem;
  line-height: .8rem;
}

.border-right-line{
  border-right-width: 1px !important;
  border-right-color: #007ec3 !important;
  border-right-style: solid;
}

.agenda-mobile{
  display: none;
  background-color: #fff;
  border-width: 0px;
  width: 100% !important;
  border-radius: 10px;
  overflow: hidden;
}

.breakout-card{
  border-radius: 10px;
  border: 1px solid #ccc;
}

.speaker-img{
  width: 100%;
}

.keynote-wrap{
  width: 100%;
}

.keynote-header{
  background-color: red;
  padding: 10px 20px 40px 20px;
  display: block;
  background: rgb(0,178,173);
  background: linear-gradient(177deg, rgba(0,178,173,1) 0%, rgba(0,185,134,1) 52%, rgba(0,192,93,1) 100%);
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  color: #000;
  font-size: 1.1rem;
  font-weight: 600;
}

.keynote-body{
  background-color: #fff;
  border-radius: 30px;
  margin-top: -30px;
  color: #000;
  width: 100%;
  padding: 20px;
}

.image-profile{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.border-10{
  border-radius: 10px !important;
}

.border-20{
  border-radius: 20px !important;
}

.MuiOutlinedInput-root{
  border-radius: 10px !important;
}

.modal-body{
  background-color: aliceblue;
  padding: 1.5rem !important;
}

.MuiOutlinedInput-input{
  background-color: #fff !important;
}

.modal-content{
  border-radius: 20px !important;
  overflow: hidden;
}

.modal-content .close{
  background-color: #ccc;
  margin-right: -2px !important;
  margin-top: -2px !important;
  padding: 0px !important;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.modal-content .close span{
  font-size: 2rem;
}

.modal-title{
  font-weight: 600 !important;
}

.footer{
  padding: 20px;
  background-color: #292929;
  color: #fff;
  text-align: center;
  font-size: .9rem;
}

.doorprize{
  padding: 40px 30px 20px 30px;
}

.doorprize .title{
  font-size: 1.4rem;
  font-weight: 600;
  font-style: italic !important;
}

.doorprize img{
  width: 200px;
  margin-top: -80px;
  object-fit: contain;
}

.doorprize .subtitle{
  font-size: 1.1rem;
  font-weight: 600;
  font-style: italic !important;
}

.sponsor{
  font-size: 1.1rem;
  letter-spacing: 6px;
}

.sponsor-item:hover{
  transform: scale(1.3);
  transition: transform .2s ease;
}

.line-ntaf{
  height: 1px;
  background-color: #007ec3;
}

@media (min-width: 769px) and (max-width: 991px) {
  .mobile{
    display: none;
  }
  
  .desktop{
    display: block;
  }

  .container{
    max-width: 100% !important;
    width: 100%;
  }
  .agenda-main{
    width: 20vw;
  }
}

@media (max-width: 767px) {
  .mobile{
    display: block !important;
  }
  
  .desktop{
    display: none !important;
  }

  .main-logo{
    height: 25px;
  }

  .key-topic-title{
    font-weight: bold;
    font-size: 12px;
  }

  .agenda{
    display: none;
  }

  .agenda-mobile{
    display: block;
  }

  .key-topic{
    background-color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .doorprize{
    padding: 20px 20px 20px 10px;
  }

  .doorprize .title{
    font-size: 1rem;
    font-weight: 600;
    font-style: italic !important;
  }
  
  .doorprize img{
    width: 100px;
    object-fit: contain;
    margin-top: -40px;
  }
  
  .doorprize .subtitle{
    font-size:.8rem;
    font-weight: 600;
    font-style: italic !important;
  }
  
}

@media (max-width: 576px) {
  .mobile{
    display: block !important;
  }
  
  .desktop{
    display: none !important;
  }

  .schedule-wrapper img.title{
    width: 250px;
  }
}



